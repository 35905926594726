/* REVIEW: ibocan global olarak Multiselect Dark Theme Sıkıntısı Giderildi */

.mso.dark .dropdown-container {
  display: inline-block;
  background-color: transparent;
  width: 100%;
}

/*  Dark mode da multiselectlerin alttaki elementleri kaydirmamasi icin yazildi eklendi  */
.mso.dark .dropdown-container .dropdown-content .panel-content {
  position: absolute;
  top: 0;
  left: 0;
}
.mso.dark .dropdown-container .dropdown-content .panel-content {
  display: inline-block;
  background-color: #363636;
  width: 100%;
}
.mso.dark
  .dropdown-container
  .dropdown-content
  .panel-content
  .select-item.selected {
  background: #575454;
}
.mso.dark
  .dropdown-container
  .dropdown-content
  .panel-content
  .select-item:hover {
  background: #636262;
}
.mso.dark .dropdown-container input[type="text"] {
  display: inline-block;
  background-color: transparent;
  width: 100%;
}
.mso .dropdown-container:focus-within {
  box-shadow: none;
  border-color: transparent;
}

.mso.error.dark .dropdown-container {
  display: inline-block;
  background-color: transparent;
  border-color: red !important;
  border-width: 2px !important;
  width: 100%;
}

.mso.error.semi-dark .dropdown-container {
  display: inline-block;
  background-color: transparent;
  border-color: red !important;
  border-width: 2px !important;
  width: 100%;
}

.mso.error.light .dropdown-container {
  display: inline-block;
  background-color: transparent;
  border-color: red !important;
  border-top-width: 0px !important;
  border-right-width: 0px !important;
  border-left-width: 0px !important;
  border-bottom-width: 2px !important;
  width: 100%;
}

.rmsc .dropdown-container:focus-within {
  border-top-width: 0px !important;
  border-right-width: 0px !important;
  border-left-width: 0px !important;
  border-bottom-width: 2px !important;
}

/*  light mode da multiselectlerin alttaki elementleri kaydirmamasi icin yazildi eklendi  */
.mso .dropdown-container .dropdown-content .panel-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
/* No absolute  */
.mso.noAbsolute .dropdown-container .dropdown-content .panel-content {
  position: unset;
}
