
:root {
  --hue: 220deg;
  --base-bg-sat: 20%;
  --base-bg-lum: 12%;
  --base-bg: hsl(var(--hue), var(--base-bg-sat), var(--base-bg-lum));
  --base-fg-sat: 50%;
  --base-fg-lum: 80%;
  --base-fg: hsl(var(--hue), var(--base-fg-sat), var(--base-fg-lum));
  --filter-fg: saturate(100%) brightness(100%);
  --module-bg-sat: 18%;
  --module-bg-lum: 27%;
  --module-bg: hsl(var(--hue), var(--module-bg-sat), var(--module-bg-lum));
}

body {
  background-color: hsl(220deg, 20%, 12%);
  background-color: var(--base-bg);
}

div.notice {
  font-size: 4em;
  text-align: center;
  margin-top: 2em;
}
div.small-notice {
  font-size: 2.5em;
}

.world {
  fill: hsl(220deg, 50%, 80%);
  fill: var(--base-fg);
  width: 1em;
  height: 1em;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  white-space: nowrap;
  border: 2px solid hsl(220deg, 50%, 80%);
  border: 2px solid var(--base-fg);
  box-sizing: border-box;
  background-image: url(#icon-world);
}

.world svg {
  width: 4em;
  height: 1em;
  margin-top: calc(-2px + -0.9em);
  display: inline;
  -webkit-animation: world-scroll 3s linear infinite;
          animation: world-scroll 3s linear infinite;
}

@-webkit-keyframes world-scroll {
  from {
    margin-left: -2.75em;
  }
  to {
    margin-left: -1em;
  }
}

@keyframes world-scroll {
  from {
    margin-left: -2.75em;
  }
  to {
    margin-left: -1em;
  }
}

.dropdown-container:focus-within{
    z-index: 9999 !important;
}


.dropdown-content:focus{
    z-index: 9999 !important;
}

.dropdown-container:focus-within {
    z-index: 9999 !important;
}

.dropdown-content:focus-within {
    z-index: 9999 !important;
}

.MuiTableRow-root {
    vertical-align: top !important
}
.rmsc .dropdown-content {
    position: unset !important;
}

.rmsc .dropdown-heading .dropdown-heading-value {
    width: 100% !important
}

#form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
  flex-direction: column;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  font-family: "Montserrat";
  background-color: #7b2cbf;
  color: #f7fff7;
  display: flex;
  align-items: center;
  font-size: 18px;
  border: none;
  border-radius: 20px;
  margin: 10px;
  padding: 7.5px 50px;
  cursor: pointer;
}

.upload-button:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

@-webkit-keyframes slide {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -120px 60px;
  }
}

@keyframes slide {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -120px 60px;
  }
}

#InfoBanner {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.reversed {
  display: inline-block;
  padding: 0.3em;
  padding-left: 0.3em;
  margin-left: 0.8em;
  position: relative;
  text-align: center;
  vertical-align: middle;
  line-height: 1;
  color: #fff;
  font-size: 15px;
  background-color: #fff3cd;
  color: #856404;
}
.reversed:before,
.reversed:after {
  content: "";
  width: 0;
  height: 0;
  right: -0.8em;
  position: absolute;
  top: 0;
  border-top: 0.8em solid #fff3cd;
}
.reversed:after {
  top: auto;
  bottom: 0;
  border-top: none;
  border-bottom: 0.8em solid #fff3cd;
}
.reversedRight:before,
.reversedRight:after {
  border-right: 0.8em solid transparent;
  right: -0.8em;
}
.reversedRight {
  width: 17px;
  border-radius: 5px 0px 0px 5px;
  -webkit-animation: tilt 2s infinite;
          animation: tilt 2s infinite;
}
@-webkit-keyframes tilt {
  0% {
    left: 0%;
  }
  50% {
    left: 9px;
  }
  100% {
    left: 0px;
  }
}
@keyframes tilt {
  0% {
    left: 0%;
  }
  50% {
    left: 9px;
  }
  100% {
    left: 0px;
  }
}
.reversedLeft {
  margin-left: 20px;
  border-radius: 0px 5px 5px 0px;
}
.reversedLeft:before,
.reversedLeft:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 0.8em solid transparent;
  left: -0.8em;
  position: absolute;
}
.reversedLeft:after {
  top: auto;
  bottom: 0;
  border-top: 0.8em solid #fff3cd;
  border-bottom: none;
}
.reversedLeft:before {
  bottom: auto;
  top: 0;
  border-bottom: 0.8em solid #fff3cd;
  border-top: none;
}

.bgAnimation {
  background-color: #fff3cd;
  background-image: linear-gradient(
      45deg,
      #fff3cd 25%,
      transparent 25%,
      transparent 75%,
      #fff3cd 75%,
      #fff3cd
    ),
    linear-gradient(
      -45deg,
      #fff3cd 25%,
      transparent 25%,
      transparent 75%,
      #fff3cd 75%,
      #fff3cd
    );
  background-size: 60px 60px;
  -webkit-animation: slide 4s infinite linear;
          animation: slide 4s infinite linear;
}

.rmsc .dropdown-container {
  border-radius: 0px !important;
  border: none !important;
  border-bottom: 1px solid !important;
}
.rmsc .dropdown-container:focus-within {
  box-shadow: none !important;
  border: 1px solid #ccc !important;
  z-index: 999;
}
.rmsc .dropdown-heading-value {
  text-align: start !important;
}
.logCheckBox[type="checkbox"] {
  cursor: pointer;
}

#logTable {
  overflow-x: scroll;
}
.ages {
  color: black;
}

.child-ages-list-modal {
  width: 100%;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 99;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  border-radius: 2px;
  padding: 1rem;
  display: none;
}

.child-ages-list-modal-item {
  width: 100%;
  border-radius: 2px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}
.child-ages-list-modal-item-buton {
  outline: none;
  border: none;
  border-radius: 3px;
  background-color: #33658a;
  padding: 0.3rem;
  cursor: pointer;
  color: white;
  width: 5rem;
}

.child-ages-list-modal-item-buton:active {
  border: 1px solid black;
  background-color: #547894;
}

.child-ages-list-modal-item-input {
  outline: none;
  border: none;
  box-shadow: 0 0 2px black;
  border-radius: 2px;
  color: black;
  width: 7rem;
  text-align: center;
  padding: 0.15rem;
}
.child-ages-modal-template {
  max-height: 20rem;
  overflow-y: scroll;
}
.child-ages-modal-item-text {
  width: 100%;
  text-align: center;
  letter-spacing: 0.2rem;
  height: 1.2rem;
  padding: 1rem;
  border: 0.1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 3px;
  background-color: white !important;
}

/* company first page company accourdion style */
.accourdionOpentoTop {
  position: absolute !important;
  width: -webkit-fill-available !important;
  z-index: 9999 !important;
}
/* .visibleOverflow {
  overflow-y: visible !important;
 
  
}
 .visibleOverflow .MuiCollapse-wrapperInner .MuiPaper-root > div:nth-of-type(2) {
  overflow-x: auto !important; 
   overflow-y: visible !important; 

} 

 .visibleOverflow .MuiCollapse-wrapperInner .MuiPaper-root > div:nth-of-type(2) >div > div {
  overflow-x: scroll !important;
  overflow-y: visible !important;

}  */

/* REVIEW: ibocan global olarak Multiselect Dark Theme Sıkıntısı Giderildi */

.mso.dark .dropdown-container {
  display: inline-block;
  background-color: transparent;
  width: 100%;
}

/*  Dark mode da multiselectlerin alttaki elementleri kaydirmamasi icin yazildi eklendi  */
.mso.dark .dropdown-container .dropdown-content .panel-content {
  position: absolute;
  top: 0;
  left: 0;
}
.mso.dark .dropdown-container .dropdown-content .panel-content {
  display: inline-block;
  background-color: #363636;
  width: 100%;
}
.mso.dark
  .dropdown-container
  .dropdown-content
  .panel-content
  .select-item.selected {
  background: #575454;
}
.mso.dark
  .dropdown-container
  .dropdown-content
  .panel-content
  .select-item:hover {
  background: #636262;
}
.mso.dark .dropdown-container input[type="text"] {
  display: inline-block;
  background-color: transparent;
  width: 100%;
}
.mso .dropdown-container:focus-within {
  box-shadow: none;
  border-color: transparent;
}

.mso.error.dark .dropdown-container {
  display: inline-block;
  background-color: transparent;
  border-color: red !important;
  border-width: 2px !important;
  width: 100%;
}

.mso.error.semi-dark .dropdown-container {
  display: inline-block;
  background-color: transparent;
  border-color: red !important;
  border-width: 2px !important;
  width: 100%;
}

.mso.error.light .dropdown-container {
  display: inline-block;
  background-color: transparent;
  border-color: red !important;
  border-top-width: 0px !important;
  border-right-width: 0px !important;
  border-left-width: 0px !important;
  border-bottom-width: 2px !important;
  width: 100%;
}

.rmsc .dropdown-container:focus-within {
  border-top-width: 0px !important;
  border-right-width: 0px !important;
  border-left-width: 0px !important;
  border-bottom-width: 2px !important;
}

/*  light mode da multiselectlerin alttaki elementleri kaydirmamasi icin yazildi eklendi  */
.mso .dropdown-container .dropdown-content .panel-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
/* No absolute  */
.mso.noAbsolute .dropdown-container .dropdown-content .panel-content {
  position: unset;
}

