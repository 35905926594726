.dropdown-container:focus-within{
    z-index: 9999 !important;
}


.dropdown-content:focus{
    z-index: 9999 !important;
}

.dropdown-container:focus-within {
    z-index: 9999 !important;
}

.dropdown-content:focus-within {
    z-index: 9999 !important;
}

.MuiTableRow-root {
    vertical-align: top !important
}
.rmsc .dropdown-content {
    position: unset !important;
}

.rmsc .dropdown-heading .dropdown-heading-value {
    width: 100% !important
}
