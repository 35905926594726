@keyframes slide {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -120px 60px;
  }
}

#InfoBanner {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.reversed {
  display: inline-block;
  padding: 0.3em;
  padding-left: 0.3em;
  margin-left: 0.8em;
  position: relative;
  text-align: center;
  vertical-align: middle;
  line-height: 1;
  color: #fff;
  font-size: 15px;
  background-color: #fff3cd;
  color: #856404;
}
.reversed:before,
.reversed:after {
  content: "";
  width: 0;
  height: 0;
  right: -0.8em;
  position: absolute;
  top: 0;
  border-top: 0.8em solid #fff3cd;
}
.reversed:after {
  top: auto;
  bottom: 0;
  border-top: none;
  border-bottom: 0.8em solid #fff3cd;
}
.reversedRight:before,
.reversedRight:after {
  border-right: 0.8em solid transparent;
  right: -0.8em;
}
.reversedRight {
  width: 17px;
  border-radius: 5px 0px 0px 5px;
  animation: tilt 2s infinite;
}
@keyframes tilt {
  0% {
    left: 0%;
  }
  50% {
    left: 9px;
  }
  100% {
    left: 0px;
  }
}
.reversedLeft {
  margin-left: 20px;
  border-radius: 0px 5px 5px 0px;
}
.reversedLeft:before,
.reversedLeft:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 0.8em solid transparent;
  left: -0.8em;
  position: absolute;
}
.reversedLeft:after {
  top: auto;
  bottom: 0;
  border-top: 0.8em solid #fff3cd;
  border-bottom: none;
}
.reversedLeft:before {
  bottom: auto;
  top: 0;
  border-bottom: 0.8em solid #fff3cd;
  border-top: none;
}

.bgAnimation {
  background-color: #fff3cd;
  background-image: linear-gradient(
      45deg,
      #fff3cd 25%,
      transparent 25%,
      transparent 75%,
      #fff3cd 75%,
      #fff3cd
    ),
    linear-gradient(
      -45deg,
      #fff3cd 25%,
      transparent 25%,
      transparent 75%,
      #fff3cd 75%,
      #fff3cd
    );
  background-size: 60px 60px;
  animation: slide 4s infinite linear;
}
